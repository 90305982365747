<template>
    <div class="mt-3">
        <b-row>
            Welcome {{ currentUser.firstName }}!
        </b-row>
        <b-row class="icons">
            <b-card-group deck>
                <template v-for="link in links">
                    <icon-link :prefix="link.prefix" :icon="link.icon" :title="link.title" :to="link.to"/>
                </template>
            </b-card-group>
        </b-row>
        <router-view/>
    </div>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';
import store from '@/store/store';
import IconLink from '@/views/private/shared/IconLink.vue';
import {errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        IconLink
    }
})
export default class Main extends Vue {

    get currentUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get links() {
        return this.$store.getters['icons/links'];
    }

    async beforeRouteEnter(to, from, next) {

        const user = store.getters['userSession/getUser'];

        if (!!user) {
            try {
                await store.dispatch('icons/compileIcons', user);
            }
            catch (error) {
                const vm = new Vue();
                vm.$bvToast.toast(error.message, errorToastOptions);
            }
        }

        next();
    }
}
</script>
<style scoped>

</style>
